export const baseFilePath = "template-engine-images/uploadedImages"


//live
export const apiBaseUrl1 = "https://j6z8ay0a1f.execute-api.us-east-1.amazonaws.com/live/";
export const apiBaseUrl2 = "https://0i22oiysad.execute-api.us-east-1.amazonaws.com/live/";


export const destIdForBusinus= "68a21760-b1ab-11ec-9848-4b5758ef29c7"  





//test
/* export const apiBaseUrl1 = "https://ndougjrj9j.execute-api.us-east-1.amazonaws.com/dev/";
export const apiBaseUrl2 = "https://287qejmrtb.execute-api.us-east-1.amazonaws.com/dev/"; */
export const getHeader = function () {
    const token = sessionStorage.token;
    let headers = { headers: { Authorization:  token } };
    return headers;
  };

//export const destIdForBusinus= "e00d53b0-5ff8-11eb-9da6-5da1fe198cb0"  