import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route} from 'react-router-dom';
import './index.css';

import * as serviceWorker from './serviceWorker';

import "./style/panel.css";
import $ from "jquery";
import Amplify from 'aws-amplify';
import Register from './components/Login/register';
import Login from './components/Login/Login';
import config from './awsconfig';





Amplify.configure({
	Auth: {
		mandatorySignIn: true,
		region: config.cognito.REGION,
		userPoolId: config.cognito.USER_POOL_ID,
		identityPoolId: config.cognito.IDENTITY_POOL_ID,
		userPoolWebClientId: config.cognito.APP_CLIENT_ID
	},
	API: {
		endpoints: [
			{
				name: 'testApiCall',
				endpoint: config.apiGateway.URL,
				region: config.apiGateway.REGION
			}
		]
	}
});
$(function(){
    $("#tab1").click(function(){
      $("#section").toggle();
    });
    });
   /*  $(function(){
    $("#c2").click(function(){
      $("#d2").toggle();
    });
    });
    $(function(){
      $("#c3").click(function(){
        $("#d3").toggle();
      });
      });
    $(function(){
      $("#c4").click(function(){
          $("#d4").toggle();
        });
        });
    $(function(){
       $("#c5").click(function(){
        $("#d5").toggle();
          });
          });
    $(function(){
        $("#c6").click(function(){
        $("#d6").toggle();
            });
            });
    $(function(){
        $("#c7").click(function(){
        $("#d7").toggle();
              });
              });
    $(function(){
        $("#c8").click(function(){
        $("#d8").toggle();
                });
                });   */

ReactDOM.render(<div>
    <BrowserRouter>

    <Route exact path = "/" component={Login}/>
<Route exact path = "/register" component={Register}/>

</BrowserRouter>
</div>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
