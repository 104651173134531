//for test

/*export default {
	apiGateway: {
		REGION: 'us-east-1',
		URL: 'YOUR_API_GATEWAY_URL'
	},
	cognito: {
		oauth:{
			domain: 'businus.auth.us-east-1.amazoncognito.com',
			scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
			redirectSignIn: 'http://localhost:3000/signin',
			redirectSignOut: 'http://localhost:3000/',
			responseType: 'code'
		  },
		REGION: 'us-east-1',
		USER_POOL_ID: 'us-east-1_aMS6CnM69',
		APP_CLIENT_ID: 'omqtds5c4n9i1v7njqqf3hmeh',
		IDENTITY_POOL_ID: 'us-east-1:3b4f2374-9232-4520-8f0e-cec94ad681b9'
	}
}; */

//for live

export default {
	apiGateway: {
		REGION: 'us-east-1',
		URL: 'YOUR_API_GATEWAY_URL'
	},
	cognito: {
		oauth:{
			domain: 'businuslive.auth.us-east-1.amazoncognito.com',
			scope: ['phone', 'email', 'profile', 'openid', 'locale','aws.cognito.signin.user.admin'],
			redirectSignIn: 'http://localhost:3000/signin',
			redirectSignOut: 'http://localhost:3000/',
			responseType: 'code'
		  },
		REGION: 'us-east-1',
		USER_POOL_ID: 'us-east-1_IbWUmm8Wz',
		APP_CLIENT_ID: '7lhfn9p8bvsqbtj6078tcea40a',
		IDENTITY_POOL_ID: 'us-east-1:3b4f2374-9232-4520-8f0e-cec94ad681b9'
	}
}; 

