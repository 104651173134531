import './style.css';
import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Auth } from 'aws-amplify';
import logo from '../../images/businuslogo.png';
import { Redirect } from 'react-router-dom'
import _ from 'underscore';
import axios from 'axios';
import {apiBaseUrl2, destIdForBusinus} from '../apiUtils';
let context = null;

class Register extends Component {
  constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			email: '',
      password: '',
      code:"",
      codeSent:false,
      redirect:false,
      error:false,
      errorMessage:""

    };
    
    //this.confirmSignUp = this.confirmSignUp.bind(this);
    context = this;
	}

	validateForm() {
		return this.state.email.length > 0 && this.state.password.length > 0;
	}

	handleChange = event => {
		this.setState({
			[event.target.name]: event.target.value
		});
	};




  
  handleSubmit=() =>{

 

    var data = this.state;
    


  
    axios
     .post(
       apiBaseUrl2+"businus/registerCustomer/add",
       {
         "customerEmail":data.email,
         "customerType":"online",
         "primaryContact":data.clientName,
         "companyName":data.name,
         "customerContact":data.contact,
         "mode":"online",
         assignedOrganisation:[{id:"0",name:"dashboard"}],
         spId:destIdForBusinus,
         teamLead: "NA",
         team: [],
         mode:"online",
         "by":"website"
       })
     .then(function(response) {
       debugger;
     alert("Success!");
     context.setState({isSubmitting:'done'});
     window.location.replace("/");
   //  context.fetchThreadById(context,query.id);
     })
     .catch(function(error) {
       console.log(error);
       context.setState({isSubmitting:false});
     });
 }


  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to='/signin' />
    }
  }
/* 
  confirmSignUp = async event => {
		event.preventDefault();

		this.setState({ isLoading: true });

		try {
      const user = Auth.confirmSignUp(this.state.name, this.state.code, {
        // Optional. Force user confirmation irrespective of existing alias. By default set to True.
        forceAliasCreation: true    
    }).then(data =>  this.setState({redirect:true}))
      .catch(err => 	alert(err.message));
   
		} catch (e) {
			alert(e.message);
			this.setState({ isLoading: false });
		}
  }; */

  render() {
    return (
      <div className="wrapper fadeInDown">
      <div id="formContent">
       
      {this.state.redirect ? 
       <Redirect to='/signin' /> : ''}
    


    <div className="row text-center">
                <img id="1" class="login-logo"
                  style={{ marginLeft: "-3px",width:"220px",height:"58px",marginTop:"23px" }}
                  src={require("../../images/businuslogo.png")}
                /><br/>
              
               </div>
      <h2 className="hi-text">Register Here!</h2>
      
        <div className="fadeIn first">
          <img className="userImage1" src="https://cdn.pixabay.com/photo/2016/11/18/23/38/child-1837375__340.png" id="icon" alt="User Icon" />
        </div>
      
     {this.state.error ? <div className="fadeIn first">
        <small style={{color:'red'}} >{this.state.errorMessage}</small>
     </div> : ''}
      
        {!this.state.codeSent ? 
       
        <form>
          <div className="login-form">
          <input type="text"  value={this.state.name} onChange={this.handleChange} className="fadeIn second text-box login-form-input-text" name="clientName" placeholder="Name"/>
            <input type="text" name="email" className="fadeIn second text-box login-form-input-text"  value={this.state.email} onChange={this.handleChange} placeholder="Mail"/>
          <input type="text" name="contact" className="fadeIn second text-box login-form-input-text" value={this.state.contact} onChange={this.handleChange} placeholder="Contact"/>
          {/* <input type="password" id="password" className="fadeIn third text-box login-form-input-pass" name="password" value={this.state.password} onChange={this.handleChange} placeholder="Password"/>
         
           */}

          <input type="button" onClick={this.handleSubmit} className="fadeIn fourth login-form-input-btn" value="Sign Up"/>
          </div> </form>
       
 : <form><div className="login-form">
         
 <input type="text" name="code" value={this.state.code} onChange={this.handleChange} className="fadeIn second text-box login-form-input-text" placeholder="Verification code"/>

 <input type="button" onClick={this.confirmSignUp} className="fadeIn fourth login-form-input-btn" value="Confirm Sign Up"/>
 </div>    </form>   }
       
        <div id="formFooter">
          <span className="underlineHover" onClick={()=>window.location.replace("/")} style={{fontSize:'14px'}}>Go back to Login</span>
        </div>
        
     
    
    </div>
    
     
    </div>
      
    );
  }
}

export default Register;
